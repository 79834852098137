import React from 'react'
import BaseDatePicker from '@atoms/DatePicker'
import { DAYJS_DATE_FORMAT } from '@constants/index'
import { Controller } from 'react-hook-form'
import dayjs from 'dayjs'
import { Form } from 'react-bootstrap'
import { ErrorMessage } from '@hookform/error-message'

type DatePickerProps = {
  label?: string
  control?: any
  errors?: { [x: string]: any }
  showError?: boolean
  name: string
  showLabel?: boolean
  placeholder?: string
  minDate?: Date
}

const DatePicker = ({
  label = '',
  placeholder,
  showLabel = true,
  control,
  name,
  showError = true,
  errors,
  minDate
}: DatePickerProps) => {
  return (
    <Form.Group controlId={`form-${label.toLowerCase()}`} className='w-full'>
      {showLabel ? <Form.Label className='text-base capitalize'>{label}</Form.Label> : ''}
      <Controller
        control={control}
        name={name as string}
        render={({ field: { onChange, value } }) => {
          return (
            <BaseDatePicker
              placeholderText={placeholder}
              minDate={minDate ?? null}
              selectedDate={new Date(dayjs(value).format(DAYJS_DATE_FORMAT))}
              onChangeSelectedDate={(newDate: Date | null) => {
                onChange(dayjs(newDate).format(DAYJS_DATE_FORMAT))
              }}
            />
          )
        }}
      />
      {showError ? (
        <ErrorMessage
          errors={errors}
          name={name ?? ''}
          render={({ message }) => <Form.Text className='text-danger mt-2 block'>{message}</Form.Text>}
        />
      ) : (
        <></>
      )}
    </Form.Group>
  )
}

export default DatePicker
