import React, { useCallback } from 'react'
import { Form } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { debounce } from 'lodash'
import BaseSelect from '@atoms/Select'
import { isCriticalOption, platformsOption } from '@pages/AppVersion/constant'
import Input from '@components/Form/Input'

interface FormSearchProps {
  onSearch: (searchTerm: SearchParam) => void
}
export interface SearchParam {
  platform?: string
  is_critical?: boolean
  version_code?: string
}

const FormSearch = ({ onSearch }: FormSearchProps) => {
  const {
    control,
    watch,
    register,
    formState: { errors }
  } = useForm<SearchParam>({
    mode: 'onTouched',
    reValidateMode: 'onChange'
  })

  // Watch for changes in the form fields
  const watchedFields = watch()
  // eslint-disable-next-line
  const debouncedSearch = useCallback(debounce(onSearch, 300), [])

  const addAllOption = (option: any) => {
    return [
      ...option,
      {
        value: '',
        label: 'All'
      }
    ]
  }

  return (
    <Form className='d-flex gap-3 w-full'>
      <Form.Group controlId='platformsOption' className='w-56'>
        <Form.Label className='font-bold text-sm capitalize'>version code</Form.Label>
        <Input
          errors={errors}
          register={register('version_code', {
            onChange(e) {
              console.log('🚀 ~ onChange ~ e:', e.target.value)

              debouncedSearch({ ...watchedFields, version_code: e.target.value })
            }
          })}
          placeholder='Enter version code'
          label={'version code'}
          showLabel={false}
        />
      </Form.Group>
      <Form.Group controlId='platformsOption' className='w-56'>
        <Form.Label className='font-bold text-sm capitalize'>platform</Form.Label>
        <Controller
          name={'platform'}
          control={control}
          render={({ field: { onBlur, onChange, value } }) => {
            return (
              <BaseSelect
                placeholder={'Select platform app'}
                options={addAllOption(platformsOption)}
                value={value === undefined ? '' : value}
                onChange={(e) => {
                  debouncedSearch({ ...watchedFields, platform: e.value !== '' ? e.value : undefined })
                  onChange(e.value)
                }}
              />
            )
          }}
        />
      </Form.Group>
      <Form.Group controlId='isCriticalOption' className='w-56'>
        <Form.Label className='font-bold text-sm capitalize'>is critical</Form.Label>
        <Controller
          name={'is_critical'}
          control={control}
          render={({ field: { onBlur, onChange, value } }) => {
            return (
              <BaseSelect
                placeholder={'Select platform app'}
                options={addAllOption(isCriticalOption)}
                value={value === undefined ? '' : value}
                onChange={(e) => {
                  debouncedSearch({ ...watchedFields, is_critical: e.value !== '' ? e.value : undefined })
                  onChange(e.value)
                }}
              />
            )
          }}
        />
      </Form.Group>
    </Form>
  )
}

export default FormSearch
