const isCriticalOption = [
  {
    value: true,
    label: 'Yes'
  },
  {
    value: false,
    label: 'No'
  }
]

const platformsOption = [
  {
    value: 'ios',
    label: 'Ios'
  },
  {
    value: 'android',
    label: 'Android'
  }
]

export { isCriticalOption, platformsOption }
