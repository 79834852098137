import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { IAppVersion } from '@store/types/AppVersion'
import { Form } from 'react-bootstrap'
import { userPaths } from '@routes/index'
import Button from '@atoms/Button'
import { useNavigate, useParams } from 'react-router-dom'
import Input from '@components/Form/Input'
import InputNumber from '@components/Form/InputNumber'
import Select from '@components/Form/Select'
import { isCriticalOption, platformsOption } from '../constant'
import Textarea from '@components/Form/Textarea'
import DatePicker from '@components/Form/DatePicker'
import { zodResolver } from '@hookform/resolvers/zod'
import { AppVersionUpdateSchema } from '../schema'
import { getAppVersionById, updateAppVersionById } from '@store/appVersion'
import { useNotification } from '@context/Notification'
import { useLoading } from '@layouts/Loading'
import { useErrorContext } from '@context/Error'

// type Props = {}

type AppVersionParam = Omit<IAppVersion, 'id'>

const EditAppVersion = () => {
  const errorContext = useErrorContext()

  const navigate = useNavigate()
  // eslint-disable-next-line
  const [loading, setLoading] = useState<boolean>(false)

  const { showNotification } = useNotification()

  const { disposeLoading, onLoading } = useLoading()

  const param = useParams()

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid }
  } = useForm<AppVersionParam>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: zodResolver(AppVersionUpdateSchema)
  })

  const updateValue = (data: IAppVersion) => {
    setValue('build_number', data.build_number)
    setValue('description', data.description)
    setValue('is_critical', data.is_critical)
    setValue('platform', data.platform)
    setValue('version_code', data.version_code)
    setValue('release_date', data.release_date)
  }

  const fetchAppVersion = async () => {
    try {
      onLoading()
      const data = await getAppVersionById(parseInt(param?.id as unknown as string))
      updateValue(data)
    } catch (error: any) {
      errorContext.setError(new Error(error as string))
    } finally {
      disposeLoading()
    }
  }

  const onSubmit = async (data: AppVersionParam) => {
    try {
      setLoading(true)
      await updateAppVersionById(parseInt(param?.id as unknown as string), data)
      showNotification({
        message: 'Edit App Version Successfully',
        type: 'success',
        onOk: () => {
          navigate(`${userPaths?.appVersion}`)
        }
      })
    } catch (error: any) {
      showNotification({
        message: error?.message ?? 'Error Unknown',
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchAppVersion()
    // eslint-disable-next-line
  }, [param?.id])

  return (
    <div className='w-full h-full flex flex-col gap-3'>
      <h1 className='font-bold text-2xl mb-4'>Edit App Version</h1>
      <div className='w-full flex justify-center'>
        <Form onSubmit={handleSubmit(onSubmit)} className='w-full max-w-[90%]'>
          <div className='row'>
            <div className='col-xl-6 col-12'>
              <Form.Group className='mb-3'>
                <Input errors={errors} register={register('version_code')} label={'version code'} />
              </Form.Group>
            </div>
            <div className='col-xl-6 col-12'>
              <Form.Group className='mb-3'>
                <InputNumber
                  errors={errors}
                  register={register('build_number', {
                    valueAsNumber: true
                  })}
                  label={'build number'}
                />
              </Form.Group>
            </div>
            <div className='col-xl-6 col-12'>
              <Form.Group className='mb-3'>
                <Select
                  options={platformsOption}
                  control={control}
                  errors={errors}
                  name='platform'
                  label='platform'
                  placeholder='Select platform app'
                />
              </Form.Group>
            </div>
            <div className='col-xl-6 col-12'>
              <Form.Group className='mb-3'>
                <Select
                  options={isCriticalOption}
                  control={control}
                  errors={errors}
                  name='is_critical'
                  label='is critical'
                  placeholder='Select status critical'
                />
              </Form.Group>
            </div>
          </div>

          <Form.Group className='mb-3'>
            <DatePicker control={control} errors={errors} name='release_date' label='release date' />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Textarea errors={errors} register={register('description')} label={'description'} />
          </Form.Group>
          <Form.Group className='mb-3  w-full flex justify-end'>
            <div className='action flex gap-2'>
              <Button className='mb-3 min-w-[80px]' type='submit' loading={loading} disabled={!isDirty || !isValid}>
                Save
              </Button>
              <Button
                className='mb-3 btn-danger min-w-[80px]'
                type='button'
                onClick={() => navigate(`${userPaths?.appVersion}`)}
                disabled={loading}
              >
                Cancel
              </Button>
            </div>
          </Form.Group>
        </Form>
      </div>
    </div>
  )
}

export default EditAppVersion
