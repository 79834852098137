/* eslint-disable */
import { useNotification } from '@context/Notification'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CreateNotificationSchema, TCreateNotification } from '../schema'
import { Controller, useForm } from 'react-hook-form'
import { userPaths } from '@routes/index'
import { Form } from 'react-bootstrap'
import Input from '@components/Form/Input'
import Textarea from '@components/Form/Textarea'
import Select from '@components/Form/Select'
import { OptionCategory } from '../constants'
import Button from '@atoms/Button'
import Switch from '@atoms/Switch'
import BaseDatePicker from '@atoms/DatePicker'
import dayjs from 'dayjs'
import { DAYJS_DATE_FORMAT } from '@constants/index'
import { ContextComponentProvider, useComponentContext } from '../context'
import { createNotification } from '@store/notification'
import { revertToUTC } from '@utils/convertTimezone'
import { useTranslation } from 'react-i18next'

const CreateNotification = () => {
  const navigate = useNavigate()
  // eslint-disable-next-line
  const [loading, setLoading] = useState<boolean>(false)
  const { showNotification } = useNotification()
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(dayjs());
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs().add(1, 'day'));

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid }
  } = useForm<TCreateNotification>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: zodResolver(CreateNotificationSchema),
    defaultValues: {
      category: 1,
      description: {
        en: '',
        ja: '',
      },
      end_announcemented_at: dayjs().add(1, 'day').format(DAYJS_DATE_FORMAT),
      start_announcemented_at: dayjs().format(DAYJS_DATE_FORMAT),
      status: 2,
      title: {
        en: '',
        ja: '',
      }
    }
  })

  const { t } = useTranslation()
  const onSubmit = async (data: TCreateNotification) => {
    try {
      setLoading(true)
      const valueConvertDateUTC: TCreateNotification = {
        ...data,
        end_announcemented_at: revertToUTC(data.end_announcemented_at),
        start_announcemented_at: revertToUTC(data.start_announcemented_at)
      }
      await createNotification(valueConvertDateUTC)
      showNotification({
        message: translation('CREATE_NOTIFICATION_SUCCESSFULLY'),
        type: 'success',
        onOk: () => {
          navigate(`${userPaths?.notification}`)
        }
      })
    } catch (error: any) {
      showNotification({
        message: error?.message ?? 'Error Unknown',
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }
  const { translation } = useComponentContext()
  const warpTranslationOption = useCallback(
    (option: { value: string | number; label: string }[]) => {
      return option.map((item) => {
        return { ...item, label: translation(item.label) }
      })
    },
    [translation]
  )

  useEffect(() => {
    
  }, [])

  return (
    <div className='w-full h-full flex flex-col gap-3'>
      <h1 className='font-bold text-2xl mb-4'>{translation('CREATE_NOTIFICATION')}</h1>
      <div className='w-full flex justify-center'>
        <Form onSubmit={handleSubmit(onSubmit)} className='w-full max-w-[90%]'>
          <div className='mb-3 row'>
            <div className='col-12 col-xl-6 mb-3'>
              <Input
                label={translation('TITLE.JA')}
                errors={errors}
                register={register('title.ja')}
                placeholder={translation('PLEASE_ENTER_NAME', {
                  nameToLowCase: translation('TITLE.JA').toLocaleLowerCase()
                })}
                t={(key: string) =>
                  translation(key, {
                    name: translation('TITLE.JA'),
                    nameToLowCase: translation('TITLE.JA').toLocaleLowerCase()
                  })
                }
              />
              <Input
                label={translation('TITLE.EN')}
                errors={errors}
                register={register('title.en')}
                placeholder={translation('PLEASE_ENTER_NAME', {
                  nameToLowCase: translation('TITLE.EN').toLocaleLowerCase()
                })}
                t={(key: string) =>
                  translation(key, {
                    name: translation('TITLE.EN'),
                    nameToLowCase: translation('TITLE.EN').toLocaleLowerCase()
                  })
                }
              />
            </div>
            <div className='col-12 col-xl-6 mb-3'>
              <Select
                options={warpTranslationOption(OptionCategory)}
                control={control}
                errors={errors}
                name='category'
                label={translation('CATEGORY')}
                placeholder={translation('SELECT_NAME', { nameToLowCase: translation('CATEGORY').toLocaleLowerCase() })}
              />
            </div>
          </div>
          <div className='mb-3'>
            <Textarea
              label={translation('DESCRIPTION.JA')}
              errors={errors}
              register={register('description.ja')}
              placeholder={translation('PLEASE_ENTER_NAME', {
                nameToLowCase: translation('DESCRIPTION.JA').toLocaleLowerCase()
              })}
              t={(key: string) =>
                translation(key, {
                  name: translation('DESCRIPTION.JA'),
                  nameToLowCase: translation('DESCRIPTION.JA').toLocaleLowerCase()
                })
              }
            />
            <Textarea
              label={translation('DESCRIPTION.EN')}
              errors={errors}
              register={register('description.en')}
              placeholder={translation('PLEASE_ENTER_NAME', {
                nameToLowCase: translation('DESCRIPTION.EN').toLocaleLowerCase()
              })}
              t={(key: string) =>
                translation(key, {
                  name: translation('DESCRIPTION.EN'),
                  nameToLowCase: translation('DESCRIPTION.EN').toLocaleLowerCase()
                })
              }
            />
          </div>
          <div className='mb-3 row'>
            <Form.Group className='col-12 col-xl-6'>
              <Controller
                control={control}
                name='start_announcemented_at'
                render={({ field: { onChange, value } }) => {
                  return (
                    <BaseDatePicker
                      label={translation('START_ANNOUNCEMENTED_AT')}
                      minDate={dayjs().toDate()}
                      selectedDate={new Date(dayjs(value).format(DAYJS_DATE_FORMAT))}
                      onChangeSelectedDate={(newDate: Date | null) => {
                        setStartDate(dayjs(newDate));
                        onChange(dayjs(newDate).format(DAYJS_DATE_FORMAT))
                      }}
                    />
                  )
                }}
              />
            </Form.Group>
            <Form.Group className='col-12 col-xl-6'>
              <Controller
                control={control}
                name='end_announcemented_at'
                render={({ field: { onChange, value } }) => {
                  return (
                    <BaseDatePicker
                      label={translation('END_ANNOUNCEMENTED_AT')}
                      minDate={dayjs().toDate()}
                      selectedDate={new Date(dayjs(value).format(DAYJS_DATE_FORMAT))}
                      onChangeSelectedDate={(newDate: Date | null) => {
                        setEndDate(dayjs(newDate));
                        onChange(dayjs(newDate).format(DAYJS_DATE_FORMAT))
                      }}
                    />
                  )
                }}
              />
            </Form.Group>
          </div>
          <div className='mb-3'>
            <Form.Group>
              <Form.Label className='text-base capitalize'>{translation('IS_DRAFT')}</Form.Label>
              <Controller
                name='status'
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Switch
                      onChange={(e) => {
                        onChange(e.target.checked ? 1 : 2)
                      }}
                      checked={value === 1 ? true : false}
                      size='md'
                      loading={loading}
                    />
                  )
                }}
              />
            </Form.Group>
          </div>
          <Form.Group className='mb-3  w-full flex justify-end'>
            <div className='action flex gap-2'>
              <Button
                className='mb-3 min-w-[80px]'
                type='submit'
                loading={loading}
                disabled={
                  !isDirty || !isValid || loading
                  || !(startDate?.isAfter(dayjs().toDate()) && endDate?.isAfter(dayjs().toDate()) && startDate?.isBefore(endDate))
                }
              >
                {t('CREATE')}
              </Button>
              <Button
                className='mb-3 btn-danger min-w-[80px]'
                type='button'
                onClick={() => navigate(`${userPaths?.notification}`)}
                disabled={loading}
              >
                {t('CANCEL')}
              </Button>
            </div>
          </Form.Group>
        </Form>
      </div>
    </div>
  )
}
const wrapContext = () => {
  return (
    <ContextComponentProvider>
      <CreateNotification />
    </ContextComponentProvider>
  )
}

export default wrapContext
