import { useEffect, useRef, useState } from 'react'
import BaseTable, { Header, TableRef } from '@atoms/Table'
import { IAppVersion, IListAppVersion, TAppVersionListParams } from '@store/types/AppVersion'
import { Button } from 'react-bootstrap'
import { BsPencilSquare } from 'react-icons/bs'
import Pagination from '@atoms/Pagination'
import FormSearch, { SearchParam } from './components/FormSearch'
// import { useNotification } from '@context/Notification'
// import { ConfirmDialogRef } from '@components/ConfirmDialog'
import { getListAppVersion } from '@store/appVersion'
import dayjs from 'dayjs'
import { DAYJS_DATE_FORMAT } from '@constants/index'
// import { useNotification } from '@context/Notification'
import { userPaths } from '@routes/index'
import { useNavigate } from 'react-router-dom'

type Props = {}

const AppVersion = (props: Props) => {
  const baseTableRef = useRef<TableRef>(null)

  // const confirmRef = useRef<ConfirmDialogRef>(null)

  const [dataAppVersion, setDataAppVersion] = useState<IListAppVersion>()

  const [appVersionParam, setAppVersionParam] = useState<TAppVersionListParams>({
    page: 1,
    page_size: 10
  })

  const navigate = useNavigate()

  // const { showNotification } = useNotification()

  const headers: Header[] = [
    {
      label: 'version code',
      key: 'version_code',
      render: (value: IAppVersion) => <div className='whitespace-nowrap'>{value.version_code}</div>
    },
    {
      label: 'platform',
      key: 'platform',
      render: (value: IAppVersion) => <div className='whitespace-nowrap'>{value.platform}</div>
    },
    {
      label: 'build number',
      key: 'build_number',
      render: (value: IAppVersion) => <div className='whitespace-nowrap'>{value.build_number}</div>
    },
    {
      label: 'release date',
      key: 'release_date',
      render: (value: IAppVersion) => (
        <div className='whitespace-nowrap'>{dayjs(value.release_date).format(DAYJS_DATE_FORMAT)}</div>
      )
    },
    {
      label: 'description',
      key: 'description',
      render: (value: IAppVersion) => (
        <div
          className='text-ellipsis overflow-hidden whitespace-nowrap'
          title={value.description}
          style={{ width: '100%', maxWidth: 500 }}
        >
          {value.description}
        </div>
      )
    },
    {
      label: 'is critical',
      key: 'is_critical',
      render: (value: IAppVersion) => <div className='whitespace-nowrap'>{value.is_critical ? 'Yes' : 'No'}</div>,
      width: '10',
      align: 'center'
    },
    {
      label: 'Actions',
      key: 'action_key',
      width: '10',
      render: (value: IAppVersion) => (
        <div className='flex flex-row gap-2'>
          <Button onClick={() => navigate(`${userPaths?.appVersion}/${value.id}`)}>
            <BsPencilSquare />
          </Button>
        </div>
      )
    }
  ]
  const onPageChange = (page: string | number) => {
    setAppVersionParam({ ...appVersionParam, page: Number(page) })
  }
  const onSearch = (value: SearchParam) => {
    setAppVersionParam({ ...appVersionParam, ...value, page: Number(1) })
  }
  // const onDelete = async (id: number) => {
  //   try {
  //     confirmRef.current?.setLoading(true)
  //     await deleteAppVersionById(id)
  //     showNotification({
  //       message: 'Delete App Version Successfully',
  //       type: 'success',
  //       onOk() {
  //         fetchListAppVersion()
  //       }
  //     })
  //   } catch (error: any) {
  //     showNotification({
  //       message: error?.message ?? 'Error Unknown',
  //       type: 'error'
  //     })
  //   } finally {
  //     confirmRef.current?.setOpen(false)
  //     confirmRef.current?.setLoading(false)
  //   }
  // }

  const fetchListAppVersion = async () => {
    try {
      baseTableRef?.current?.setLoading(true)
      const data = await getListAppVersion(appVersionParam)
      setDataAppVersion(data)
    } catch (error: any) {
    } finally {
      baseTableRef?.current?.setLoading(false)
    }
  }
  useEffect(() => {
    fetchListAppVersion()
    // eslint-disable-next-line
  }, [appVersionParam])

  return (
    <div className='w-full h-full flex flex-col gap-3'>
      <div>
        <div>
          <span className='font-bold text-2xl'>App Version Management</span>
        </div>
        <div className='flex flex-row justify-between items-end my-4'>
          <FormSearch onSearch={onSearch} />
          <Button
            variant='primary'
            className='whitespace-nowrap'
            type='button'
            onClick={() => navigate(`${userPaths?.appVersion}/create`)}
          >
            Create App Version
          </Button>
        </div>
      </div>
      <div className='flex-1 h-[50%]'>
        <BaseTable headers={headers} itemsRow={dataAppVersion?.result ?? []} ref={baseTableRef} />
      </div>
      <div className='flex justify-center'>
        <Pagination
          onPageChange={onPageChange}
          onChangePageSize={() => null}
          totalCount={dataAppVersion?.total ?? 0}
          currentPage={appVersionParam.page ?? 0}
          siblingCount={1}
          pageSize={appVersionParam.page_size}
        />
      </div>

      {/* <ConfirmDialog ref={confirmRef} cancelText='No, cancel' okText="Yes, I'm sure">
        <div className='flex flex-col items-center gap-2'>
          <AiFillDelete className='text-[4rem] text-gray-400' />
          <p className='mb-4'>Are you sure you want to delete this item?</p>
        </div>
      </ConfirmDialog> */}
    </div>
  )
}

export default AppVersion
