import * as z from 'zod'

const RegexVersionCode = /^(\d+\.)?(\d+\.)?(\*|\d+)$/

const message = {
  max: (value: number, name: string) => `${name} must contain at most ${value} character(s)`,
  number: {
    invalid_type_error: 'Please enter a numeric value',
    max: (value: number, name: string) => `${name} must be less than or equal to ${value}`,
    min: (value: number, name: string) => `${name} must be greater than or equal to  ${value}`
  }
}

export const AppVersionCreateSchema = z.object({
  platform: z.union([z.literal('ios'), z.literal('android')]),
  is_critical: z.boolean(),
  version_code: z
    .string()
    .min(1, { message: 'Version code is required. Please input version code' })
    .refine((value) => RegexVersionCode.test(value), {
      message: 'Invalid version code format. Must be in the format x.y.z where x, y, and z are numeric components or *.'
    }),
  build_number: z.number(message.number).max(100000, message.number.max(10000, 'Build Number')).int(),
  release_date: z.string(),
  description: z.string().max(225, message.max(225, 'Description'))
})

export const AppVersionUpdateSchema = z.object({
  platform: z.union([z.literal('ios'), z.literal('android')]),
  is_critical: z.boolean(),
  version_code: z
    .string()
    .min(1, { message: 'Version code is required. Please input version code' })
    .refine((value) => RegexVersionCode.test(value), {
      message: 'Invalid version code format. Must be in the format x.y.z where x, y, and z are numeric components or *.'
    }),
  build_number: z.number(message.number).max(100000, message.number.max(10000, 'Build Number')).int(),
  release_date: z.string(),
  description: z.string().max(225, message.max(225, 'Description'))
})

export const AppVersionSearch = z.object({})

type TCreateAppVersion = z.TypeOf<typeof AppVersionCreateSchema>
type TUpdateAppVersion = z.TypeOf<typeof AppVersionUpdateSchema>

export type { TCreateAppVersion, TUpdateAppVersion }
