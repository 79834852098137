import { IAppVersion, IListAppVersion, TAppVersionListParams } from './types/AppVersion'
import axios from '@libs/axios'

const prefix = '/admin/check-version'

const getListAppVersion = async (params: TAppVersionListParams) => {
  const { data } = await axios.get<{ data: IListAppVersion }>(`${prefix}`, {
    params
  })
  return data.data
}

const getAppVersionById = async (id: number) => {
  const { data } = await axios.get(`${prefix}/${id}`)
  if (data?.errors) {
    throw new Error(data?.message ?? 'ERROR UNKNOWN')
  }
  return data
}

const createAppVersion = async (dataCreate: IAppVersion) => {
  const { data } = await axios.post(`${prefix}`, {
    ...dataCreate
  })
  if (data?.errors) {
    throw new Error(data?.message ?? 'ERROR UNKNOWN')
  }
  return data
}

const deleteAppVersionById = async (id: number) => {
  return
}

const updateAppVersionById = async (id: number, dataUpdate: IAppVersion) => {
  const { data } = await axios.put(`${prefix}/${id}`, {
    ...dataUpdate
  })
  if (data?.errors) {
    throw new Error(data?.message ?? 'ERROR UNKNOWN')
  }
  return data
}

export { getListAppVersion, getAppVersionById, deleteAppVersionById, updateAppVersionById, createAppVersion }
